import Axios from "../axios";
import {toForm, outErr} from "./apiConfig";

const url = "/api/wallet";
const creditUrl = "/api/yodgs";


export default {
    //查看钱包
    balance(out) {
        Axios.get(url + "/balance").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    preDeposit(data, out) {
        Axios.post(url + "/preDeposit", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },


    preDepositModification(data, out) {
        Axios.post(url + "/preDeposit/modification", data, null, {
            "Content-Type": "multipart/form-data"
        }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    deposit(data, out) {
        Axios.post(url + "/deposit", data, null, {
            "Content-Type": "multipart/form-data"
        }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    modification(data, out) {
        Axios.post(url + "/paymentPwd/modification", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
    withdraw(data, out) {
        Axios.post(url + "/withdraw", toForm(data)).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    creditDeposit(data, out) {
        Axios.post(creditUrl + "/deposit", data, null, {"Content-Type": "multipart/form-data"}).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    getIndianBank(out) {
        Axios.get(creditUrl + "/indianBank").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    indianBankDeposit(data, out) {
        Axios.post(creditUrl + "/indianBank/deposit", data, null, {
            "Content-Type": "multipart/form-data"
        }).then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },

    preWithdrawCheck(out) {
        Axios.get(url + "/preWithdraw").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },



}
